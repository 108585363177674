import logo from '../images/gbc_logo.png';
const Home = ({ setShow }) => {
    return (
        <div className="home" id={"div-home"}>
            <h2  id={"div-home-header"}>Welcome to Gay Bear Capital</h2>
            <h4>Where bad banking is allowed</h4>
            <p>
                <img src={logo} alt="logo" />
            </p>
        </div>
    );
}
 
export default Home;