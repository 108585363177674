import { useParams, useHistory } from "react-router";
import { DataStore } from '@aws-amplify/datastore';
import { Account, Transaction } from '../models';
import { useState, useEffect } from 'react';
import AccountDetails from "../components/Accounts/AccountDetails";
import { Button } from 'react-bootstrap';
import { withAuthenticator } from "@aws-amplify/ui-react";

const initialState = { username: '', currency: 'USD', balance: 0.00, Transactions: []};

const AccountDeposit = ( { user, setShow, setText }) => {
  const history = useHistory();
    const { id } = useParams();
    const [account, setAccount] = useState(initialState);
    const [formState, setFormState] = useState({amount: 0});
    useEffect(() => {
      let mounted = true;
      async function fetchAccount() {
        try {
          const account = await DataStore.query(Account, c => c.id("eq", id));
          return account;
        } catch (err) {
          console.log('error fetching items');
          return [];
        }
      }
      fetchAccount().then((account) => {
        if(mounted){
          setAccount(account);
        }
      })
      return () => { mounted = false }
    }, [id])
   
    const updateAccount = async () => {
      try {
          const original = await DataStore.query(Account, id);
          await DataStore.save(
              Account.copyOf(original, updated => {
                  updated.username = user
                  updated.currency = original.currency
                  updated.balance = formState.amount + original.balance
              })
          ).then((account) => {
            createTransaction(account);
          })
      } catch (err) {
          console.log(err)
      }
    }

  function setInput(key, value) {
    let v = value
    if(key === 'amount'){
      v = Number(value);
    }
    setFormState({ ...formState, [key]: v })
  }

  async function createTransaction(account) {
  try {
    const transaction = await DataStore.save(new Transaction({
        accountID: account.id,
        username: account.username,
        action: `Deposited: ${formState.amount} - ${account.currency}`
    }));
    setText("Deposit Successful!");
    setShow(true)
    setTimeout(() => {
      history.push("/");
    }, 3000);
    return (transaction, account);
  } catch (err) {
    console.log('error updating store items:', err);
  }
}
    return (
        <div className="home">
         <AccountDetails account={ account[0] }/>
         <div className="form">
          <h2>Deposit Funds</h2>
          <label>Amount</label>
          <input
            type='number'
            step='0.01'
            onChange={event => setInput('amount', event.target.value)}
            value={formState.amount}
            min="0"
          />
          <Button onClick={ updateAccount }>Deposit</Button>
          </div>
        </div>
        )
    }
 
export default withAuthenticator(AccountDeposit);