import NavBar from './components/NavBar';
import Home from './pages/Home';
import { BrowserRouter as Router , Route, Switch} from 'react-router-dom';
import Amplify,  { Hub }  from "aws-amplify";
import awsExports from "./aws-exports";
import { useState } from 'react';
import Login from './pages/Login';
import NotFound from './pages/NotFound';
import AccountCreate from './pages/AccountCreate';
import Accounts from './pages/Accounts';
import AccountDeposit from './pages/AccountDeposit';
import AccountOverview from './pages/AccountOverview';
import AccountWithdrawal from './pages/AccountWithdrawal';
import AccountHistory from './pages/AccountHistory';
import ToastIt from './components/ToastIt';

Amplify.configure(awsExports);

function App() {
  const [user, setUser] = useState();
  const [show, setShow] = useState(false);
  const [text, setText] = useState("");

  const listener = (data) => {
      switch (data.payload.event) {
          case 'signIn':
              console.log('user signed in');
              break;
          case 'signUp':
              console.log('user signed up');
              break;
          case 'signOut':
              console.log('user signed out');
              setUser();
              window.location.reload("/");
              break;
          case 'signIn_failure':
              console.log('user sign in failed');
              break;
          case 'tokenRefresh':
              console.log('token refresh succeeded');
              break;
          case 'tokenRefresh_failure':
              console.log('token refresh failed');
              break;
          case 'configured':
              console.log('the Auth module is configured');
              break;
          default:
          
      }
  }

  Hub.listen('auth', listener);
  return (
    <Router>
      <div className="App">
      <NavBar />
      <div className="content">
        <Switch>
          <Route exact path="/">
            {
              user ? <Accounts user={ user }/> : <Home setShow={ setShow }/>
            }
          </Route>
          <Route exact path="/login">
            <Login setUser={ setUser }/>
          </Route>
          <Route path="/account/create">
            <AccountCreate user={ user } setShow={ setShow } setText={ setText}/>
          </Route>
          <Route path="/account/overview/:id">
            <AccountOverview user={ user }/>
          </Route>
          <Route path="/account/deposit/:id">
            <AccountDeposit user={ user } setShow={ setShow } setText={ setText}/>
          </Route>
          <Route path="/account/withdrawal/:id">
            <AccountWithdrawal user={ user } setShow={ setShow } setText={ setText} />
          </Route>
          <Route path="/account/history/">
            <AccountHistory user={ user }/>
          </Route>
          <Route>
            <NotFound />
          </Route>
         </Switch>
      </div>
    </div>
    <ToastIt setShow={ setShow } text={ text } show={ show }/>
    </Router>
  );
}

export default App;