import { DataStore } from '@aws-amplify/datastore';
import { Account, Transaction } from '../models';
import { useState } from 'react';
import { Button } from 'react-bootstrap';
import { withAuthenticator  } from '@aws-amplify/ui-react';

const initialState = { username: '', currency: 'USD', balance: 0.00, Transactions: []}

const AccountCreate = ({ user, setShow, setText }) => {
   const [formState, setFormState] = useState(initialState);
  function setInput(key, value) {
    let v = value
    if(key === 'balance'){
      v = Number(value);
    }
    setFormState({ ...formState, [key]: v })
  }

  async function createTransaction(account) {
  try {
    if (!formState.currency || !formState.balance) return
    const transaction = await DataStore.save(new Transaction({
        accountID: account.id,
        username: account.username,
        action: `Created Account: ${account.balance} - ${account.currency}`
    }));
    return (transaction, account);
  } catch (err) {
    console.log('error updating store items:', err);
  }
}
  async function createAccount() {
    try {
      if (!formState.currency || !formState.balance) return
        await DataStore.save(new Account({ ...formState, username: user }))
      .then((account) => {
          createTransaction(account);
      })
      setFormState(initialState);
      setText("Deposit Successful!");
      setShow(true)
    } catch (err) {
      console.log('error creating new account', err);
    }
  }
  return (
    <div className="form">
      <h2>Create Account</h2>
      <label>Currency</label>
      <select
            value={ formState.currency }
            onChange={event => setInput('currency', event.target.value)}
          >
            <option value="USD">USD</option>
            <option value="BTC">BTC</option>
            <option value="ETH">ETH</option>
            <option value="XRP">XRP</option>
        </select>
      <label>Initial Balance</label>
      <input
        type='number'
        step='0.01'
        onChange={event => setInput('balance', event.target.value)}
        value={formState.balance}
        min="0"
      />
      <Button onClick={ createAccount }>Create Account</Button>
      </div>
  )
}
 
export default withAuthenticator(AccountCreate);