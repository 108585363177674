/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:4ff624d5-ec8d-4701-a7db-9abb4d3a73f8",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_vT3gymWHD",
    "aws_user_pools_web_client_id": "3h7i7akd82qef5n0ifrt4u51cm",
    "oauth": {},
    "aws_cognito_login_mechanisms": [],
    "aws_cognito_signup_attributes": [
        "PHONE_NUMBER",
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "ON",
    "aws_cognito_mfa_types": [
        "SMS",
        "TOTP"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": [
            "REQUIRES_LOWERCASE",
            "REQUIRES_NUMBERS",
            "REQUIRES_SYMBOLS",
            "REQUIRES_UPPERCASE"
        ]
    },
    "aws_appsync_graphqlEndpoint": "https://tmcayrvsszgaplianlmfcjcslu.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-vh7xeb6befcxzkmtvjqd3mqjle",
    "aws_content_delivery_bucket": "dickpruittbankingapp-dev",
    "aws_content_delivery_bucket_region": "us-east-1",
    "aws_content_delivery_url": "http://dickpruittbankingapp-dev.s3-website-us-east-1.amazonaws.com"
};


export default awsmobile;
