import { Row } from "react-bootstrap";

const TransactionDetails = ({ transaction }) => {
    return (
        <div className="item-details">
            { transaction && 
                <div className="item-preview">
                    <Row><h3>{ transaction.id.toUpperCase()}</h3></Row>
                    <Row><p>Action: { transaction.action }</p></Row>
                    <Row><p>Timestamp: { transaction.createdAt }</p></Row>
              </div>
            }
        </div>
       
    );
}
 
export default TransactionDetails;