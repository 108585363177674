import { DataStore } from '@aws-amplify/datastore';
import { Transaction } from '../../models';
import { useState, useEffect } from 'react';
import TransactionDetails from './TransactionDetails';

const TransactionHistory = ({ id }) => {
    const [transactions, setTransactions] = useState([]);
    useEffect(() => {
      async function fetchTransactions() {
        try {
              const transactions = (await DataStore.query(Transaction)).filter((t) => t.accountID === id);
              return transactions;   
        } catch (err) {
          console.log('error fetching transactions');
          return [];
        }
      }
      
      let mounted = true;
      fetchTransactions().then((transactions) => {
        if(mounted){
          setTransactions(transactions);
        }
      })
      return () => { mounted = false }
    }, [id])

  
    return (
        <div className="home">
         { transactions &&
             transactions.map((transaction) => {
                 return <TransactionDetails transaction={ transaction } key={ transaction.id }/>
             })
         }
        </div>
        )
    }
 
export default TransactionHistory;