import { Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

const AccountList = ({ accounts }) => {
    return (
        <div className="item-list">
        {accounts.map(i => (
          <div className="item-preview" key={i.id} >
            <h2>{ i.currency + "-" + i.id.split("-")[0].toUpperCase()}</h2>
            <p>Balance: { i.balance }</p>
            <Row>
              <Col><Link to={`/account/overview/${i.id}`} >Account Details</Link></Col>
            </Row>
            <hr />
            <Row>
              <Col><Link to={`/account/withdrawal/${i.id}`} >Withdraw</Link></Col>
              <Col><Link to={`/account/deposit/${i.id}`} >Deposit</Link></Col>
            </Row>
          </div>
        ))}
      </div>
    );
}
 
export default AccountList;