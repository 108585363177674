import logo from '../images/logo-16x16.png'
import {Row, Col,Toast } from 'react-bootstrap'

function ToastIt({ setShow, text, show }) {
    return (
      <Row>
        <Col xs={6}>
          <Toast onClose={() => setShow(false)} show={show} delay={3000} autohide animation="false" position="bottom-start">
            <Toast.Header>
              <img
                src={logo}
                className="rounded me-2"
                alt="gbc logo"
              />
              <strong className="me-auto">Bad Banking</strong>
            </Toast.Header>
            <Toast.Body>{ text }</Toast.Body>
          </Toast>
        </Col>
      </Row>
    );
  }
export default ToastIt