import { DataStore } from '@aws-amplify/datastore';
import { Account } from '../models';
import { useState, useEffect } from 'react';
import AccountList from '../components/Accounts/AccountList';
import AccountCreate from './AccountCreate';
import { withAuthenticator } from '@aws-amplify/ui-react';

const Accounts = ({ user }) => {
   const [accounts, setAccounts] = useState([]);
  useEffect(() => {
    let mounted = true;
    fetchAccounts().then((accounts) => {
      if(mounted){
        setAccounts(accounts);
      }
    })
    return () => { mounted = false }
  })

  async function fetchAccounts() {
    try {
      const accounts = await DataStore.query(Account, c => c.username("eq", user));
      return accounts;
    } catch (err) {
      console.log('error fetching items');
      return [];
    }
  }


  return (
    <div className="home">
      {
        accounts.length > 0 && <><h2>Accounts</h2><AccountList accounts={ accounts.sort((a, b) => a.currency < b.currency ? -1 : 1 ) }/></>
      }
      {
        accounts.length === 0 && <AccountCreate user={ user } />
      }
    </div>
  )
}
 
export default withAuthenticator(Accounts);