import { useParams } from "react-router";
import { DataStore } from '@aws-amplify/datastore';
import { Account } from '../models';
import { useState, useEffect } from 'react';
import AccountDetails from "../components/Accounts/AccountDetails";
import TransactionHistory from "../components/Accounts/TransactionHistory";
import { withAuthenticator } from "@aws-amplify/ui-react";
const initialState = { username: '', currency: 'USD', balance: 0.00, Transactions: []}
const AccountOverview = () => {
    const { id } = useParams();
    const [account, setAccount] = useState(initialState);
    useEffect(() => {
      let mounted = true;
      async function fetchAccount() {
      try {
        const account = await DataStore.query(Account, c => c.id("eq", id));
        return account;
      } catch (err) {
        console.log('error fetching items');
        return [];
      }
    }
      fetchAccount().then((account) => {
        if(mounted){
          setAccount(account);
        }
      })
      return () => { mounted = false }
    }, [id])
  
    return (
        <div className="home">
         <AccountDetails account={ account[0] }/>
         <TransactionHistory id={id} />
        </div>
        )
    }
 
export default withAuthenticator(AccountOverview);