import { DataStore } from '@aws-amplify/datastore';
import { Transaction } from '../models';
import { useState, useEffect } from 'react';
import TransactionDetails from '../components/Accounts/TransactionDetails';
import { withAuthenticator } from '@aws-amplify/ui-react';

const AccountHistory = ({ user }) => {
    const [transactions, setTransactions] = useState([]);
    useEffect(() => {
      let mounted = true;
      async function fetchTransactions() {
        try {
          if(user){
              const transactions = (await DataStore.query(Transaction)).filter((t) => t.username === user);
              return transactions;
          }   
        } catch (err) {
          console.log('error fetching transactions');
          return [];
        }
      }
      fetchTransactions().then((transactions) => {
        if(mounted){
          setTransactions(transactions);
        }
      })
      return () => { mounted = false }
    }, [user])
  
    
  
    return (
        <div className="home">
            <h2>Account History</h2>
         { transactions &&
             transactions.map((transaction) => {
                 return <TransactionDetails transaction={ transaction } key={ transaction.id }/>
             })
         }
        </div>
        )
    }
 
export default withAuthenticator(AccountHistory);