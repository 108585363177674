import { Auth } from 'aws-amplify';
import { NavDropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Button } from 'react-bootstrap';
const UserDropdown = ({ handleLogout }) => {
    return (
        <>
            <NavDropdown  title={Auth.user.attributes.email} id='navbarAccountDropDown'>
                <NavDropdown.Item to="/account/create" as={ Link }  id='navbarCreateAccountLink'>Create Account</NavDropdown.Item>
                <NavDropdown.Item to="/account/history"  id='navbarHistoryLink' as={ Link } >History</NavDropdown.Item>
                <NavDropdown.Item as={ Button } onClick={ handleLogout }>Signout</NavDropdown.Item>
          </NavDropdown>
        </>
    );
}
 
export default UserDropdown;