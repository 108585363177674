import { Auth } from 'aws-amplify';
import { Container, Navbar } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import UserDropdown from './UserDropdown';

const NavBar = ({ setUser }) => {
  async function handleLogout() {
    await Auth.signOut();
  }

    return (
      <Navbar>
        <Container>
        <Navbar.Brand to="/" as={ Link }>
          MERN Bank
        </Navbar.Brand>
        <Navbar.Collapse className="justify-content-end">
          {
            Auth.user ? <UserDropdown handleLogout={ handleLogout } /> : <Link to="/login">Login</Link>
          }
        </Navbar.Collapse>
        </Container>
      </Navbar>
    );
  }
   
  export default NavBar;